@import 'variables';
@import '../utils/variables';

// Font Sizes
%font-size--900 {
	font-size: 48px;
}

%font-size--800 {
	font-size: 32px;
}

%font-size--700 {
	font-size: 24px;
}

%font-size--600 {
	font-size: 20px;
}

%font-size--500 {
	font-size: 16px;
}

%font-size--400 {
	font-size: 14px;
}

// Font Weight

%font-weight--100 {
	font-weight: 100;
}

%font-weight--200 {
	font-weight: 200;
}

%font-weight--300 {
	font-weight: 300;
}

%font-weight--400 {
	font-weight: 400;
}

%font-weight--500 {
	font-weight: 500;
}

%font-weight--600 {
	font-weight: 600;
}

%font-weight--700 {
	font-weight: 700;
}

%font-weight--800 {
	font-weight: 800;
}

%font-weight--900 {
	font-weight: 900;
}

// Text color mixins

%placeholder-color {
	color: $default-placeholder-color;
}

%disabled-color {
	color: $default-disabled-color;
}

%error-color {
	color: $default-error-color;
}

// Baseline

%neo-baseline {
	margin-bottom: $neo-spacing-700;

	& > :last-child {
		margin-bottom: 0;
	}
}

%neo-baseline--small {
	margin-bottom: $neo-spacing-400;

	& > :last-child {
		margin-bottom: 0;
	}
}

.neo-baseline {
	@extend %neo-baseline;
}

.neo-baseline--small {
	@extend %neo-baseline--small;
}

.neo-baseline--none {
	margin-bottom: 0;
}
