@import '../color/color-variables';

$ccap-input-border-radius: 4px !default;
$ccap-input-height: 40px !default;

$ccap-input-color: get-shade(neo-very-dark-blue, 500) !default;
$ccap-input-background-color: get-shade(neo-light-grayish-orange, 100) !default;
$ccap-input-border-color: currentColor !default;

// focus
$ccap-input-focus-background-color: get-shade(neo-black, 0) !default;
$ccap-input-focus-border-color: get-shade(neo-pure-blue, 500) !default;

// invalid
$ccap-input-invalid-border-color: $neo-strong-red !default;

// disabled & readonly
$ccap-input-disabled-background-color: get-shade(neo-black, 50) !default;
$ccap-input-disabled-border-color: get-shade(neo-black, 200) !default;

// placeholder
$ccap-input-placeholder-color: get-shade(neo-very-dark-blue, 350) !default;
