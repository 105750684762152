@import 'neo-utils';

.no-answer {
	color: $neo-strong-red;
	margin: 0;

	&--center {
		text-align: center;
	}

	&--padded {
		padding: $base-spacing;
	}
}

.container {
	padding-left: $neo-spacing-500;
	padding-right: $neo-spacing-500;
	@extend %large-container;
}

.round-container {
	overflow: hidden;
	border-radius: 50%;

	&__image {
		width: 100%;
		height: 100%;
		background: no-repeat center;
		background-size: cover;
	}
}
