////
/// FORM ELEMENTS
////

@import 'neo-utils';

%disabled {
	opacity: 0.6;
	pointer-events: none;
	cursor: not-allowed;
}

fieldset {
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0;
}

legend {
	@extend %font-weight-medium;

	margin-bottom: $tiny-spacing;
	padding: 0;
}

input,
textarea {
	font-size: $large-font-size;
}

[type='search'] {
	appearance: none;
}

[type='checkbox'],
[type='radio'] {
	display: inline;
	margin-right: $tiny-spacing;
}

[type='file'] {
	margin-bottom: $base-spacing;
	width: 100%;
}
