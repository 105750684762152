.ef-input:disabled,
.ef-input[disabled],
.ef-input[readonly] {
	// Fixes issue with Webkit browsers
	// Remove once fixed in EF UI Kit
	/* stylelint-disable-next-line */
	-webkit-text-fill-color: $color-text--disabled;
}

// Custom Errors
.ef-input-w:not(.ef-boolean).-is-required .ef-input {
	border-color: $color-notification-attention;

	&:focus {
		box-shadow: 0 4px 12px 0 rgba($color-notification-attention, 0.2);
	}
}

.ef-input-w.ef-boolean.-is-invalid .ef-boolean__element.-radio {
	border-color: $color-notification-attention;
}

.ef-input-w,
.ef-form-fieldset,
.ef-form-label {
	max-width: initial;
}
