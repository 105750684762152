@import '../color/color-variables';
@import '../utils/variables';

$ccap-button-font-size: 16px;
$ccap-button-border-radius: $neo-border-radius;
$ccap-button-height: 40px;
$ccap-button-padding: 8px;
$ccap-button-line-height: $ccap-button-height - ($ccap-button-padding * 2);
$ccap-button-min-width: 152px;

// Disabled Buttons
$ccap-button-disabled-background-color: get-shade(neo-black, 50);
$ccap-button-disabled-color: get-shade(neo-black, 300);

// Button 500 default
$ccap-button-500-default-background-color: $neo-very-dark-blue;
$ccap-button-500-default-hover-background-color: get-shade(
	neo-very-dark-blue,
	400
);
$ccap-button-500-default-color: get-shade(neo-light-grayish-orange, 50);

// Button 500 accent
$ccap-button-500-accent-background-color: $neo-lime-green;
$ccap-button-500-accent-hover-background-color: get-shade(neo-lime-green, 300);
$ccap-button-500-accent-color: $neo-very-dark-blue;

// Button 500 danger
$ccap-button-500-danger-background-color: $neo-soft-red;
$ccap-button-500-danger-hover-background-color: get-shade(neo-soft-red, 300);
$ccap-button-500-danger-color: $neo-very-dark-blue;

// Button 400 default
$ccap-button-400-default-background-color: get-shade(
	neo-light-grayish-orange,
	100
);
$ccap-button-400-default-hover-background-color: get-shade(neo-black, 0);
$ccap-button-400-default-color: $neo-very-dark-blue;
$ccap-button-400-border-color: $neo-very-dark-blue;
$ccap-button-400-border-hover-color: $neo-very-dark-blue;

// Button 250 default
$ccap-button-250-default-background-color: get-shade(
	neo-light-grayish-orange,
	500
);
$ccap-button-250-default-hover-background-color: get-shade(
	neo-very-dark-blue,
	100
);
$ccap-button-250-default-color: $neo-very-dark-blue;

// Button 100 default
$ccap-button-100-default-background-color: transparent;
$ccap-button-100-default-hover-background-color: transparent;
$ccap-button-100-default-color: $neo-pure-blue;
$ccap-button-100-border-color: transparent;
$ccap-button-100-border-hover-color: $neo-pure-blue;
