@import 'input-variables';
@import 'input-mixins';

%ccap-input-error-outline {
	outline-color: $ccap-input-invalid-border-color;
}

%ccap-input-outline {
	outline: 2px solid Highlight;
	outline: 5px auto -webkit-focus-ring-color;
}

%ccap-input-error {
	border-color: $ccap-input-invalid-border-color;
}

%ccap-base-input {
	border-radius: $ccap-input-border-radius;
	color: $ccap-input-color;
	background-color: $ccap-input-background-color;
	border: 1px solid $ccap-input-border-color;
	margin-bottom: $neo-spacing-500;
	width: 100%;
	padding: $neo-spacing-300;

	&:focus {
		background-color: $ccap-input-focus-background-color;
		border-color: $ccap-input-focus-border-color;

		@extend %ccap-input-outline;
		@include ccap-input-error {
			@extend %ccap-input-error-outline;
		}
	}

	@include ccap-input-error {
		@extend %ccap-input-error;
	}

	&:disabled {
		background-color: $ccap-input-disabled-background-color;
		border-color: $ccap-input-disabled-border-color;
	}

	&::placeholder {
		opacity: 1;
		color: $ccap-input-placeholder-color;
	}
}
