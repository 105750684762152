ul,
ol {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

dl {
	margin: 0;
}

dt {
	@extend %font-weight-light;

	margin-bottom: $tiny-spacing;
}

dd {
	margin: 0;
	white-space: pre-wrap;
}

.standard-list {
	list-style-type: initial;
	margin-left: $base-spacing;
}

.hyphened-list {
	list-style-type: none;
	margin-left: $base-spacing;
	margin-bottom: $base-spacing;

	li::before {
		content: '— ';
	}
}
