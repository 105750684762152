@import './button-variables';
@import '../typography/base-mixins';

%ccap-base-button {
	appearance: none;
	border-radius: $ccap-button-border-radius;
	border-width: 1px;
	border-style: solid;
	cursor: pointer;
	display: inline-block;
	height: $ccap-button-height;
	font-size: $ccap-button-font-size;
	line-height: $ccap-button-line-height;
	min-width: $ccap-button-min-width;
	padding: $ccap-button-padding;
	text-align: center;
	user-select: none;
	vertical-align: middle;
	text-decoration: none;

	@extend %font-weight--500;
}
