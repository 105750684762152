@import 'base-mixins';

.heading--1 {
	@extend %font-weight--500;
	@extend %font-size--900;
}

.heading--2 {
	@extend %font-weight--400;
	@extend %font-size--800;
}

.heading--3 {
	@extend %font-weight--400;
	@extend %font-size--700;
}

.heading--4 {
	@extend %font-weight--500;
	@extend %font-size--600;
}

.neo-heading--1 {
	font-size: 28px;
	font-weight: 500;
	line-height: $neo-spacing-900;
	margin-top: $neo-spacing-700;
	margin-bottom: $neo-spacing-700;

	@include grid-media($mobile) {
		font-size: 48px;
		line-height: $neo-spacing-1300;
		margin-top: $neo-spacing-1000 * 2;
		margin-bottom: $neo-spacing-900;
		font-weight: 700;
	}
}

.neo-heading--2 {
	font-size: 24px;
	font-weight: 400;
	line-height: $neo-spacing-800;
	margin-bottom: $neo-spacing-700;

	@include grid-media($mobile) {
		font-size: 32px;
		line-height: $neo-spacing-1100;
		margin-bottom: $neo-spacing-1300;
	}
}

.neo-heading--3 {
	font-size: 18px;
	font-weight: 400;
	line-height: $neo-spacing-700;
	margin-bottom: $neo-spacing-700;

	@include grid-media($mobile) {
		font-size: 24px;
		line-height: $neo-spacing-900;
		margin-bottom: $neo-spacing-700;
	}
}

.neo-heading--4 {
	font-size: 22px;
	font-weight: 400;
	line-height: $neo-spacing-700;
	margin-bottom: $neo-spacing-700;

	@include grid-media($mobile) {
		font-size: 32px;
		line-height: $neo-spacing-1100;
		margin-bottom: $neo-spacing-700;
	}
}

.neo-heading--5 {
	font-size: 18px;
	font-weight: 700;
	line-height: $neo-spacing-700;
	margin-bottom: $neo-spacing-500;

	@include grid-media($mobile) {
		font-size: 24px;
		line-height: $neo-spacing-900;
		margin-bottom: $neo-spacing-500;
		font-weight: 400;
	}
}
