@import '@angular/cdk';
@import 'core';
@import 'ef-ui';
@import 'neo-core';
@import 'neo-utils';

@include overlay();
@include a11y-visually-hidden();

body {
	background-color: $neo-page-background;

	&.disable-scroll {
		overflow-y: hidden;
	}
}

.button {
	margin-top: $large-spacing;
	margin-bottom: $large-spacing;
}

.space-below {
	margin-bottom: $base-spacing;

	&--double {
		margin-bottom: $large-spacing;

		&:last-of-type {
			margin-bottom: unset;
		}
	}

	&:last-of-type {
		margin-bottom: unset;
	}
}

.card {
	@include grid-media($desktop) {
		padding: $base-spacing;
	}

	padding: $base-spacing $small-spacing;
	background-color: $neo-white;
	box-shadow: $box-shadow;
}

.modal-card {
	@extend .card;

	position: relative;
}

.ng-invalid.ng-touched {
	/* stylelint-disable-next-line declaration-no-important */
	border-color: $neo-strong-red !important;
}

.tile {
	&__container {
		margin-top: $base-spacing;
	}

	&__label {
		cursor: unset;
	}
}

.title {
	text-align: center;
	margin-left: $large-spacing;
	margin-right: $large-spacing;
}

.tiles {
	@extend %side-by-side;

	padding: $small-spacing $base-spacing;

	dd {
		white-space: normal;
	}
}

.hide-for-print {
	@media print {
		/* stylelint-disable-next-line declaration-no-important */
		display: none !important;
	}
}

.loading-brand-mark-wrapper {
	display: grid;
	height: 75vh;
	align-items: center;
	justify-content: center;
}

.loading-brand-mark {
	width: 40px;
	animation: heartbeat 1s ease-in-out infinite;
}

@keyframes heartbeat {
	from {
		transform: scale(1);
	}

	50% {
		transform: scale(1.2);
	}

	to {
		transform: scale(1);
	}
}
