@mixin ccap-disabled() {
	&[disabled],
	&:disabled,
	&.disabled {
		@content;
	}
}

@mixin ccap-button-theme(
	$color,
	$background-color,
	$background-hover-color,
	$border-color: $background-color,
	$border-hover-color: $background-hover-color
) {
	color: $color;
	background-color: $background-color;
	border-color: $border-color;

	&:hover,
	&:active {
		color: $color;
		background-color: $background-hover-color;
		border-color: $border-hover-color;
	}
}

@mixin ccap-button-disabled {
	@include ccap-disabled {
		pointer-events: none;
		color: $ccap-button-disabled-color;
		background-color: $ccap-button-disabled-background-color;
		border-color: $ccap-button-disabled-background-color;

		&:hover,
		&:active {
			color: $ccap-button-disabled-color;
			background-color: $ccap-button-disabled-background-color;
			border-color: $ccap-button-disabled-background-color;
		}
	}
}
