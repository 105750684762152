@import 'neo-utils';

body {
	@extend %font-weight-light;

	font-size: $large-font-size;
	font-family: $font-family;
	line-height: $base-line-height;
}

b,
strong {
	@extend %font-weight-bold;
}

h1 {
	font-size: 35px;
	line-height: 1.25;

	@extend %font-weight-bold;

	@include grid-media($mobile) {
		font-size: 55px;
	}
}

h2 {
	font-size: 28px;
	line-height: 1.25;

	@extend %font-weight-medium;

	@include grid-media($mobile) {
		font-size: 32px;
		font-weight: $font-weight-bold;
	}
}

h3 {
	font-size: 24px;
	line-height: 1.155;

	@extend %font-weight-medium;

	@include grid-media($mobile) {
		font-size: 26px;
	}
}

h4 {
	font-size: 21px;
	line-height: 1.155;

	@extend %font-weight-medium;
}

h5 {
	font-size: 17px;
	line-height: 1.25;

	@extend %font-weight-medium;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0 0 $base-spacing;
}

p {
	line-height: 1.5;
	margin: 0 0 $small-spacing;

	@extend %font-weight-light;

	&.bold {
		@extend %font-weight-bold;
	}
}

a {
	@extend %a;
}

.link-metadata {
	color: get-shade(neo-very-dark-blue, 500);

	&::before {
		margin-left: $neo-spacing-200;
		content: '(';
	}

	&::after {
		content: ')';
	}
}
