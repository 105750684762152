html {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	overflow-y: scroll;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $large-spacing 0;
}
