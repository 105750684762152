@import 'color-functions';

/* stylelint-disable color-no-hex */
$neo-vivid-pink: #ff329b;
$neo-vivid-yellow: #faed24;
$neo-soft-red: #f37772;
$neo-strong-red: #d1334a;
$neo-light-grayish-orange: #f2eade;
$neo-very-dark-blue: #04314d;
$neo-moderate-blue: #5895cf;
$neo-lime-green: #6ac4a0;
$neo-pure-blue: #0061ff;

$neo-light-brown: #f3ece1;

$neo-black: #000;
$neo-white: #fff;

$shades: (
	neo-pure-blue: generate-shade-map($neo-pure-blue),
	neo-black: generate-shade-map($neo-black),
	neo-vivid-pink: generate-shade-map($neo-vivid-pink),
	neo-vivid-yellow: generate-shade-map($neo-vivid-yellow),
	neo-soft-red: generate-shade-map($neo-soft-red),
	neo-light-grayish-orange: generate-shade-map($neo-light-grayish-orange),
	neo-very-dark-blue: generate-shade-map($neo-very-dark-blue),
	neo-moderate-blue: generate-shade-map($neo-moderate-blue),
	neo-lime-green: generate-shade-map($neo-lime-green),
);

@function get-shade($color, $shade) {
	$shade-map: map-get($shades, #{$color});
	@return map-get($shade-map, #{$shade});
}

$neo-page-background: get-shade(neo-light-grayish-orange, 100);
$neo-border-color: get-shade(neo-very-dark-blue, 500);
