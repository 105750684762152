@import 'ef-ui-variables';

/**
 * EF UI Kit imports.
 * Import individual files to avoid importing CSS that we don't need.
 * Import order is important, check EF UI Kit source code to check the correct order.
 */

/**
 * Base
 */
// normalize.css
@import '~@ef-global/web-ui/scss/base/reset';
// opinionated EF extension of normalize
@import '~@ef-global/web-ui/scss/base/defaults';
@import 'base/defaults';
// icon helpers
@import '~@ef-global/web-ui/scss/base/icons';
// animations
@import '~@ef-global/web-ui/scss/base/animations';
// utilities
@import '~@ef-global/web-ui/scss/utilities/_all.scss';

/**
 * Typography
 */
@import '~@ef-global/web-ui/scss/typography/latin';

/**
 * Components
 */
@import '~@ef-global/web-ui/scss/components/grid';
@import '~@ef-global/web-ui/scss/components/link';
@import '~@ef-global/web-ui/scss/components/button';
@import '~@ef-global/web-ui/scss/components/sections';
@import '~@ef-global/web-ui/scss/components/form';
@import '~@ef-global/web-ui/scss/components/surface';
@import '~@ef-global/web-ui/scss/components/input-text';
@import '~@ef-global/web-ui/scss/components/input-select';
@import '~@ef-global/web-ui/scss/components/input-boolean';
@import '~@ef-global/web-ui/scss/components/header';
@import '~@ef-global/web-ui/scss/components/modal';
@import '~@ef-global/web-ui/scss/components/tag';
@import 'components/input';
