@import 'variables/color-palette';

$assetsPath: '~@ef-global/web-ui/assets';
$color-primary: $ccap-blue;
$btn-primary-bg: $ccap-blue;

:root {
	--btn-primary-box-shadow: 0;
	--btn-primary-box-shadow--hover: 0; /* stylelint-disable-line custom-property-pattern */
	--btn-outlined-box-shadow: 0;
	--btn-outlined-box-shadow--hover: 0; /* stylelint-disable-line custom-property-pattern */
}

// variables, mixins etc...
@import '~@ef-global/web-ui/scss/settings/_all.scss';
