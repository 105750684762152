////
/// VARIABLES
/// @group variables
////

@import 'neo-utils';

// NOTE: This file is deprecated, no additional styles shall be added here. These variables will be removed gradually.

// Font family

$font-family: 'EFCircular', 'Helvetica Neue', Arial, sans-serif;

// Base grid size

$grid-size: 16px !default;

// The default container width

$container-width: 656px !default;

// Breakpoints
$xs-mobile: 331px;
$mobile: 768px;
$desktop: 1025px;
$xl-desktop: 1281px;

// Font Sizes
$base-font-size: 14px;
$large-font-size: 16px;
$extra-large-font-size: 20px;
// Line height
$base-line-height: 1.5 !default;
// Radius size
$base-radius: 2px;
$large-radius: 4px;
// Spacings
$tiny-spacing: $grid-size / 4;
$small-spacing: $grid-size / 2;
$base-spacing: $grid-size;
$bulky-spacing: $grid-size * 1.5;
$large-spacing: $grid-size * 2;
$extra-large-spacing: $grid-size * 4;

// Base border color
$base-border-color: get-shade(neo-black, 50) !default;
// Base border
$base-border: 1px solid $base-border-color !default;

// Base box shadow
$box-shadow: 0 2px 4px rgba(get-shade(neo-very-dark-blue, 500), 0.2);
// Base animation
$base-duration: 110ms;
$long-duration: 220ms;
$base-timing: ease;
$cubic-bezier: cubic-bezier(0.42, 0, 1, 1);
// Other Sizes
$section-top-spacing-size: 128px;
$profile-image-size: 208px;
$links-image-size: 125px;
$sub-section-image-size: 250px;
$tile-height: 128px;
$tile-width: 120px;
