/*
Examples:

// Containers for regular tiles look like this
<div class="tileset">
	<div class="tile">…</div>
</div>

// Containers for wide tiles have class modifier `--has-wide-tiles`
<div class="tileset tileset--has-many tileset--has-4 tileset--has-wide-tiles">
	<div class="tile tile--wide">…</div>
</div>

// Checked tiles have class modifier `--checked`
	<div class="tile tile--checked">…</div>

// An exception - when tiles are meant to be clickable (not readonly), then they have class modifier `--clickable`
	<div class="tile tile--clickable">…</div>

*/

@import 'neo-utils';

%space-bottom {
	margin-bottom: $small-spacing;

	&:last-child {
		margin-bottom: 0;
	}
}

.tile {
	@include tile($tile-height);

	$icon-size: $base-spacing * 4;

	&__input {
		@include sr-only;

		&.ng-invalid.ng-touched ~ label {
			// radios
			border: 1px solid $neo-strong-red;
		}

		.invalid.ng-touched & ~ label {
			// checkboxes
			border: 1px solid $neo-strong-red;
		}
	}

	&__label {
		@include clearfix;

		color: get-shade(neo-very-dark-blue, 500);
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: $small-spacing;
		background-color: $neo-white;
		border: $base-border;
		border-radius: $tiny-spacing;
		user-select: none;
		transition: box-shadow $base-duration $cubic-bezier,
			border-color $base-duration $cubic-bezier;
	}

	&__rating {
		$size: 12px;

		display: flex;
		justify-content: space-between;
		width: $icon-size;
		margin: 0 auto;

		.star {
			width: $size;
			height: $size;

			&--empty {
				color: get-shade(neo-black, 50);
			}

			&--full {
				color: get-shade(neo-vivid-yellow, 500);
			}
		}
	}

	&__title {
		@extend %space-bottom;

		font-size: $base-font-size;
		text-align: center;
	}

	&__sub-title {
		@extend %space-bottom;

		text-align: center;
	}

	&--wide {
		.tile__label {
			flex-direction: row;
			justify-content: flex-start;
		}

		.tile__column--left {
			width: $icon-size;
			margin-right: $small-spacing;
		}

		.tile__column--right {
			overflow: hidden;
		}

		.tile__title {
			text-align: unset;
		}

		.tile__content {
			word-break: break-word;
		}
	}

	&--checked &__label {
		background-color: get-shade(neo-pure-blue, 500);
		border-color: get-shade(neo-pure-blue, 500);
		color: $neo-white;
	}

	&--clickable &__label {
		cursor: pointer;

		&:hover {
			border-color: get-shade(neo-pure-blue, 500);
			box-shadow: $box-shadow;
		}
	}

	ccap-svg {
		@extend %space-bottom;

		width: 100%;
		height: $icon-size;
		+ .tile__title {
			flex: none;
			margin-bottom: auto;
		}
	}
}

$total-width: $tile-width + $base-spacing;

$tile-s: $total-width * 2 + $large-spacing;
$width-s: $total-width * 2;
$tile-m: $total-width * 3 + $large-spacing;
$width-m: $total-width * 3;
$tile-l: $total-width * 4 + $large-spacing;
$width-l: $total-width * 4;
.tileset {
	@include clearfix;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&--has-many {
		justify-content: flex-start;
		margin: 0 auto;
		height: 100%;
		width: $width-s;
		.tile {
			float: left;
		}
	}
	&--has-3 {
		@include grid-media($tile-m) {
			width: $width-m;
		}
	}
	&--has-4 {
		@include grid-media($tile-m) {
			width: $width-m;
		}
		@include grid-media($tile-l) {
			width: $width-l;
		}
	}
	&--has-too-many {
		@include grid-media($tile-m) {
			width: $width-m;
		}
		@include grid-media($tile-l) {
			width: $width-l;
		}
	}
	&--has-wide-tiles {
		@include grid-media($tile-m) {
			width: $width-s;
		}
		@include grid-media($tile-l) {
			width: $width-l;
		}
	}
}
