@import '../utils/variables';
@import './button-mixins';
@import './button-variables';
@import './button-placeholders';

.ccap-button-500 {
	@extend %ccap-base-button;
	@include ccap-button-disabled;
}

.ccap-button-500--default {
	@include ccap-button-theme(
		$ccap-button-500-default-color,
		$ccap-button-500-default-background-color,
		$ccap-button-500-default-hover-background-color
	);
}

.ccap-button-500--accent {
	@include ccap-button-theme(
		$ccap-button-500-accent-color,
		$ccap-button-500-accent-background-color,
		$ccap-button-500-accent-hover-background-color
	);
}

.ccap-button-500--danger {
	@include ccap-button-theme(
		$ccap-button-500-danger-color,
		$ccap-button-500-danger-background-color,
		$ccap-button-500-danger-hover-background-color
	);
}

.ccap-button-400 {
	@extend %ccap-base-button;
	@include ccap-button-disabled;
}

.ccap-button-400--default {
	@include ccap-button-theme(
		$ccap-button-400-default-color,
		$ccap-button-400-default-background-color,
		$ccap-button-400-default-hover-background-color,
		$ccap-button-400-border-color,
		$ccap-button-400-border-hover-color
	);
}

.ccap-button-250 {
	@extend %ccap-base-button;
	@include ccap-button-disabled;
}

.ccap-button-250--default {
	@include ccap-button-theme(
		$ccap-button-250-default-color,
		$ccap-button-250-default-background-color,
		$ccap-button-250-default-hover-background-color
	);
}

.ccap-button-100 {
	@extend %ccap-base-button;
	@include ccap-button-disabled;
}

.ccap-button-100--default {
	@include ccap-button-theme(
		$ccap-button-100-default-color,
		$ccap-button-100-default-background-color,
		$ccap-button-100-default-hover-background-color,
		$ccap-button-100-border-color,
		$ccap-button-100-border-hover-color
	);
}

.ccap-icon-button {
	appearance: none;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	vertical-align: middle;
}
