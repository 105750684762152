/* stylelint-disable color-no-hex */
$ccap-beige: #f1eae1;
$ccap-pink: #ff329b;
$ccap-peach: #f37873;
$ccap-yellow: #fdf552;
$ccap-green: #68c39f;
$ccap-blue: #5794cf;
$ccap-navy: #003c64;

$ccap-light-blue: #eef4fa;
$ccap-light-pink: #fef1f1;
$ccap-light-green: #f0f9f6;
$ccap-light-yellow: #fefabd;
$ccap-gray: #efefef;
$ccap-dark-beige: #dad3c8;
