@import 'variables';
@import 'neo-utils';

%a {
	&[target='_blank'] {
		&::after {
			content: ' ↗';
			font-size: 0.75em;
		}
	}

	&.link--no-arrow::after {
		content: '';
	}

	@include ccap-disabled {
		pointer-events: none;
		color: get-shade(neo-black, 300);
	}
}
