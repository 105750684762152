////
/// BUTTONS
////

// A button class which can be extended as necessary
//
// @example markup
//		<a class="button" href="#">My button-styled link</div>

.button {
	@extend %button-base;

	&--link {
		padding: 0;
		margin: 0;
		text-align: inherit;
		background: transparent;
		border: 0;
		font-size: inherit;
		display: inline;
		cursor: pointer;
		@extend %a;
	}
}
