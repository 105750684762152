@import 'input-placeholders';
@import 'input-variables';

.ccap-input__postfix {
	@extend %ccap-base-input;
}

.ccap-input-postfix-group {
	display: flex;
	flex-direction: group;

	.ccap-input {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;

		// stop postfix element from covering the focus border
		&:focus {
			position: relative;
			z-index: 1;
		}
	}

	.ccap-input__postfix {
		position: relative;
		color: get-shade(neo-pure-blue, 500);
		border-color: get-shade(neo-very-dark-blue, 500);
		height: $ccap-input-height;
		width: auto;
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		white-space: pre;
		cursor: pointer;

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: $neo-spacing-200;
			bottom: $neo-spacing-200;
			left: 0;
			width: 1px;
			background-color: get-shade(neo-pure-blue, 500);
		}
	}
}
