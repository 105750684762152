@use 'sass:math';
@import 'neo-utils';

// Font weights
$font-weight-light: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

%font-weight-light {
	font-weight: $font-weight-light;
}

%font-weight-medium {
	font-weight: $font-weight-medium;
}

%font-weight-bold {
	font-weight: $font-weight-bold;
}

@mixin question-list-add-button-container {
	background-color: $neo-page-background;
	border-top: $base-border;
	border-bottom: $base-border;
	cursor: pointer;
	display: flex;
	flex: 1;
	align-items: center;
	height: 50px;
	justify-content: flex-end;
	margin: 0 (-$base-spacing);

	h5 {
		margin: 0;
	}

	ccap-svg {
		margin-right: $base-spacing;
		margin-left: $small-spacing;
	}
}

@mixin abstract-question-list {
	&__pill {
		margin-top: $base-spacing;
		margin-bottom: $small-spacing;
		padding: $small-spacing;
		border-radius: $small-spacing * 0.5;
		color: $neo-white;
		width: 100%;

		&--pending {
			background-color: get-shade(neo-vivid-yellow, 500);
			color: get-shade(neo-very-dark-blue, 500);
		}

		&--rejected {
			background-color: $neo-strong-red;
		}

		&--approved {
			background-color: get-shade(neo-lime-green, 500);
			color: get-shade(neo-very-dark-blue, 500);
		}
	}

	&__edit {
		position: relative;
		width: 100%;
		height: 1px;
		background-color: get-shade(neo-black, 50);
		margin-bottom: $base-spacing;

		:host ::ng-deep & ccap-svg {
			cursor: pointer;
			position: absolute;
			top: -$base-spacing;
			right: 0;

			svg {
				padding: $small-spacing;
			}
		}
	}

	&__add {
		@include question-list-add-button-container;
	}
}

@mixin tile($min-height, $width: $tile-width) {
	width: $width;
	min-height: $min-height;
	margin-right: $small-spacing;
	margin-left: $small-spacing;
	margin-bottom: $base-spacing;
}

/// Helper mixin for arrow.
///
/// @example markup
///		<span class="arrow-container">
///			<span class="arrow"></span>
///		</span>
///
/// @example scss
///		.arrow {
///			@include arrow;
///		{

@mixin arrow(
	$arrow-size: 6px,
	$rotation: 0,
	$color: get-shade(neo-pure-blue, 500)
) {
	pointer-events: none;
	border: solid $color;
	border-width: 0 0 2px 2px;
	width: $arrow-size;
	height: $arrow-size;
	line-height: 0;
	font-size: 0;
	transform: rotate(#{$rotation}deg);

	&-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@mixin grid-media($media) {
	@media only screen and (min-width: #{$media}) {
		@content;
	}
}

@mixin grid-column($amount) {
	$width: math.div(100, 12) * $amount;

	width: #{$width} + '%';
	float: left;
	margin-left: #{(100 - $width) * 0.5} + '%';
}

@mixin clearfix {
	&::after {
		clear: both;
		content: '';
		display: block;
	}
}

/// Styles an element as a "container"
///
/// @require {mixin} grid-container
///
/// @example scss
///		@include container(68em);

@mixin container($max-width: $container-width) {
	@include clearfix;

	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;
}

%large-container {
	@include container(60em);
}

/// Makes element only visible with screen-readers. In other words, makes the element invisible
///
/// @example scss
///		@include sr-only;

@mixin sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

/// Aligns items absolutely
///
/// @example scss
///		@include absolute-align(relative);

@mixin absolute-align($position: absolute) {
	position: $position;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/// Aligns items vertically
///
/// @example scss
///		@include vertical-align(absolute);
/// TODO combine with `absolute-align`

@mixin vertical-align($position: relative) {
	position: $position;
	top: 50%;
	transform: translateY(-50%);
}

/// Specifies the input font-size.
/// Normal size on desktop, 16px on mobile
///
/// @require {mixin} grid-media
///

%side-by-side {
	@include grid-media($mobile) {
		display: flex;

		dt,
		dd {
			flex: 1;
		}

		dt {
			margin: 0 $base-spacing 0 0;
			max-width: 200px;

			p {
				margin: 0;
			}
		}
	}
}
