/// The button base style
///
/// @example scss
///		@extend %button-base;

@import 'neo-utils';

%button-base {
	@include container(380px);

	padding: $small-spacing $base-spacing;
	appearance: none;
	border: 0;
	cursor: pointer;
	display: block;
	font-size: $base-font-size;
	line-height: 1;
	text-align: center;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	width: 100%;
	border-radius: $base-radius;
	transition: background-color $base-duration $cubic-bezier,
		box-shadow $base-duration $cubic-bezier,
		transform $base-duration $cubic-bezier;
	-webkit-font-smoothing: antialiased;
}

/// The base style for the primary button
///
/// @example scss
///		@extend %button-primary;

%button-primary {
	@extend %font-weight-light;

	background-color: get-shade(neo-pure-blue, 500);
	color: $neo-white;

	&[disabled],
	&:disabled,
	&.disabled {
		color: get-shade(neo-black, 300);
		background-color: get-shade(neo-black, 50);
	}

	&:hover,
	&:focus {
		&:not(.disabled):not(:disabled) {
			color: $neo-white;
			background-color: lighten(
				saturate(get-shade(neo-pure-blue, 500), 14%),
				3%
			);
			box-shadow: 0 4px 13px rgba(get-shade(neo-very-dark-blue, 500), 0.1),
				0 3px 6px rgba(get-shade(neo-very-dark-blue, 500), 0.08);
			transform: translateY(-2px);
		}
	}
}

/// The base style for the large-sized button
///
/// @example scss
///		@extend %button-large;

%button-large {
	padding: $base-spacing;
}
