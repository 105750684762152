// NOTE: This file is deprecated, no additional styles shall be added here. These variables will be removed gradually.

$neo-grid-spacing: 4px;

$neo-spacing-200: $neo-grid-spacing;
$neo-spacing-300: $neo-grid-spacing * 2;
$neo-spacing-400: $neo-grid-spacing * 3;
$neo-spacing-500: $neo-grid-spacing * 4;
$neo-spacing-600: $neo-grid-spacing * 5;
$neo-spacing-700: $neo-grid-spacing * 6;
$neo-spacing-800: $neo-grid-spacing * 7;
$neo-spacing-900: $neo-grid-spacing * 8;
$neo-spacing-1000: $neo-grid-spacing * 9;
$neo-spacing-1100: $neo-grid-spacing * 10;
$neo-spacing-1200: $neo-grid-spacing * 11;
$neo-spacing-1300: $neo-grid-spacing * 12;

$neo-max-page-width: $neo-grid-spacing * 240;
$neo-max-inner-page-width: $neo-grid-spacing * 184;

$neo-border-radius: 3px;
