/*
 * Legal Disclaimer
 *
 *  *  all EF Group websites
 *
 * It is strictly forbidden to download or use these fonts on any other website domain or media.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-Light.eot");
	src: url("/assets/fonts/EFCircularWeb-Light.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-Light.woff2") format("woff2");
	font-weight: 300;
}

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-LightItalic.eot");
	src: url("/assets/fonts/EFCircularWeb-LightItalic.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-LightItalic.woff2") format("woff2");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-Book.eot");
	src: url("/assets/fonts/EFCircularWeb-Book.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-Book.woff2") format("woff2");
	font-weight: 400;
}

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-BookItalic.eot");
	src: url("/assets/fonts/EFCircularWeb-BookItalic.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-BookItalic.woff2") format("woff2");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-Medium.eot");
	src: url("/assets/fonts/EFCircularWeb-Medium.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-Medium.woff2") format("woff2");
	font-weight: 500;
}

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-MediumItalic.eot");
	src: url("/assets/fonts/EFCircularWeb-MediumItalic.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-MediumItalic.woff2") format("woff2");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-Bold.eot");
	src: url("/assets/fonts/EFCircularWeb-Bold.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-Bold.woff2") format("woff2");
	font-weight: 700;
}

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-BoldItalic.eot");
	src: url("/assets/fonts/EFCircularWeb-BoldItalic.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-BoldItalic.woff2") format("woff2");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-Black.eot");
	src: url("/assets/fonts/EFCircularWeb-Black.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-Black.woff2") format("woff2");
	font-weight: 900;
}

@font-face {
	font-family: "EFCircular";
	src: url("/assets/fonts/EFCircularWeb-BlackItalic.eot");
	src: url("/assets/fonts/EFCircularWeb-BlackItalic.woff") format("woff"),
		url("/assets/fonts/EFCircularWeb-BlackItalic.woff2") format("woff2");
	font-weight: 900;
	font-style: italic;
}
