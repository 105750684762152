@import 'base-mixins';

.paragraph-16--400 {
	@extend %neo-baseline;

	font-size: 16px;
	line-height: $neo-spacing-700;
	font-weight: 400;
}

.paragraph-16--500 {
	font-size: 16px;
	line-height: $neo-spacing-700;
	font-weight: 500;
}

.paragraph-16--700 {
	font-size: 16px;
	line-height: $neo-spacing-700;
	font-weight: 700;
}

.paragraph-14--400 {
	font-size: 14px;
	line-height: $neo-spacing-600;
	font-weight: 400;
	@extend %disabled-color;
}

.paragraph-14--400-error {
	@extend .paragraph-14--400;
	@extend %error-color;
}
